import React from 'react';
import SEO from '../components/seo';
import Header from '../components/header';

const DataProtection = () => (
	<>
		<Header />

		<div style={{ maxWidth: '1064px', padding: '32px', paddingTop: '112px', margin: '0 auto' }}>
			<SEO title="Datenschutzerklärung" />

			<h1>Datenschutz&shy;erklärung</h1>
			<p>
				I. Name und Anschrift des Verantwortlichen
				<br />
				Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze
				der Mitgliedstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist die:
				<br />
				<br />
				THNG GmbH
				<br />
				<br />
				Römerstraße 75
				<br />
				71229 Leonberg
				<br />
				Deutschland
				<br />
				<br />
				+49 7152 906 599 0
				<br />
				<br />
				www.thng.de
				<br />
				<br />
				II. Name und Anschrift des Datenschutzbeauftragten
				<br />
				<br />
				Der Datenschutzbeauftragte des Verantwortlichen ist:
				<br />
				<br />
				IITR Datenschutz GmbH
				<br />
				Marienplatz 2
				<br />
				80331 München
				<br />
				Tel.: +49 89 1891 7360
				<br />
				E-Mail: email@iitr.de
				<br />
				<br />
				III. Rechte der betroffenen Person
				<br />
				Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen
				Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
				<br />
				<br />
				1. Auskunftsrecht
				<br />
				Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die
				Sie betreffen, von uns verarbeitet werden.
				<br />
				<br />
				Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen
				Auskunft verlangen:
				<br />
				<br />
				(1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
				<br />
				<br />
				(2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
				<br />
				<br />
				(3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden
				personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
				<br />
				<br />
				(4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete
				Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
				<br />
				<br />
				(5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen
				Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines
				Widerspruchsrechts gegen diese Verarbeitung;
				<br />
				<br />
				(6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
				<br />
				<br />
				(7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht
				bei der betroffenen Person erhoben werden;
				<br />
				<br />
				(8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs.
				1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte
				Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die
				betroffene Person.
				<br />
				<br />
				Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten
				in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang
				können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der
				Übermittlung unterrichtet zu werden.
				<br />
				<br />
				2. Recht auf Berichtigung
				<br />
				Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern
				die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der
				Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
				<br />
				<br />
				3. Recht auf Einschränkung der Verarbeitung
				<br />
				Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden
				personenbezogenen Daten verlangen:
				<br />
				<br />
				(1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen Daten für eine Dauer bestreiten, die
				es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
				<br />
				<br />
				(2) die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und
				stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
				<br />
				<br />
				(3) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger
				benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
				benötigen, oder
				<br />
				<br />
				(4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch
				nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.
				<br />
				<br />
				Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten –
				von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
				Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen
				Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
				verarbeitet werden.
				<br />
				<br />
				Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem
				Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
				<br />
				<br />
				4. Recht auf Löschung
				<br />
				a) Löschungspflicht
				<br />
				Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten
				unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu
				löschen, sofern einer der folgenden Gründe zutrifft:
				<br />
				<br />
				(1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf
				sonstige Weise verarbeitet wurden, nicht mehr notwendig.
				<br />
				<br />
				(2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 S.1 lit. a oder
				Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die
				Verarbeitung.
				<br />
				<br />
				(3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine
				vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
				Widerspruch gegen die Verarbeitung ein.
				<br />
				<br />
				(4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.
				<br />
				<br />
				(5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen
				Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der
				Verantwortliche unterliegt.
				<br />
				<br />
				(6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
				Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
				<br />
				<br />
				b) Information an Dritte
				<br />
				Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem.
				Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren
				Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die
				Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren,
				dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder
				von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
				<br />
				<br />
				c) Ausnahmen
				<br />
				Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
				<br />
				<br />
				(1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
				<br />
				<br />
				(2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder
				der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe,
				die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
				Verantwortlichen übertragen wurde;
				<br />
				<br />
				(3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2
				lit. h und i sowie Art. 9 Abs. 3 DSGVO;
				<br />
				<br />
				(4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische
				Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a)
				genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder
				ernsthaft beeinträchtigt, oder
				<br />
				<br />
				(5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
				<br />
				<br />
				5. Recht auf Unterrichtung
				<br />
				Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem
				Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
				personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder
				Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit
				einem unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem Verantwortlichen das Recht zu,
				über diese Empfänger unterrichtet zu werden.
				<br />
				<br />
				6. Recht auf Datenübertragbarkeit
				<br />
				Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen
				bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
				Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den
				Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
				<br />
				<br />
				(1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 S.1 lit. a DSGVO oder Art. 9 Abs. 2 lit.
				a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 S.1 lit. b DSGVO beruht und
				<br />
				<br />
				(2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
				<br />
				<br />
				In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden
				personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt
				werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht
				beeinträchtigt werden. Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
				personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
				Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
				<br />
				<br />
				7. Widerspruchsrecht
				<br />
				Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die
				Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 S.1 lit. e
				oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
				Profiling.
				<br />
				<br />
				Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er
				kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
				Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
				Rechtsansprüchen.
				<br />
				<br />
				Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben
				Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten
				zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
				Direktwerbung in Verbindung steht.
				<br />
				<br />
				Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden
				personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
				<br />
				<br />
				Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft –
				ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren
				auszuüben, bei denen technische Spezifikationen verwendet werden.
				<br />
				<br />
				8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
				<br />
				Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch
				den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
				erfolgten Verarbeitung nicht berührt.
				<br />
				<br />
				9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling
				<br />
				Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich
				Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung
				entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
				<br />
				<br />
				(1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen
				erforderlich ist,
				<br />
				<br />
				(2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche
				unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und
				Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
				<br />
				<br />
				(3) mit Ihrer ausdrücklichen Einwilligung erfolgt.
				<br />
				<br />
				Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art.
				9 Abs. 1 S.1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene
				Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
				<br />
				<br />
				Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die
				Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf
				Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen
				Standpunkts und auf Anfechtung der Entscheidung gehört.
				<br />
				<br />
				10. Recht auf Beschwerde bei einer Aufsichtsbehörde
				<br />
				Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das
				Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
				Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht
				sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt. Die
				Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den
				Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs
				nach Art. 78 DSGVO.
				<br />
				IV. Allgemeines zur Datenverarbeitung
				<br />
				1. Umfang der Verarbeitung personenbezogener Daten
				<br />
				Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung
				einer funktionsfähigen Webseite sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung
				personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine
				Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen
				Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
				<br />
				<br />
				2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
				<br />
				Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person
				einholen, dient Art. 6 Abs. 1 S.1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
				<br />
				<br />
				Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
				Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 S.1 lit. b DSGVO als
				Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
				Maßnahmen erforderlich sind.
				<br />
				<br />
				Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung
				erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 S.1 lit. c DSGVO als
				Rechtsgrundlage.
				<br />
				<br />
				Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen
				Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 S.1 lit. d
				DSGVO als Rechtsgrundlage.
				<br />
				<br />
				Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten
				erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
				erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 S.1 lit. f DSGVO als Rechtsgrundlage für die
				Verarbeitung.
				<br />
				<br />
				3. Datenlöschung und Speicherdauer
				<br />
				Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der
				Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen
				oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften,
				denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt
				auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass
				eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine
				Vertragserfüllung besteht.
				<br />
				<br />
				V. Bereitstellung der Webseite und Erstellung von Logfiles
				<br />
				1. Beschreibung und Umfang der Datenverarbeitung
				<br />
				Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen vom
				Computersystem des aufrufenden Rechners.
				<br />
				Folgende Daten werden hierbei erhoben:
				<br />
				<br />
				Informationen über den Browsertyp und die verwendete Version
				<br />
				Das Betriebssystem des Nutzers
				<br />
				Den Internet-Service-Provider des Nutzers
				<br />
				Die IP-Adresse des Nutzers
				<br />
				Datum und Uhrzeit des Zugriffs
				<br />
				Webseiten, von denen das System des Nutzers auf unsere Internetseite gelangt
				<br />
				Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten
				zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt.
				<br />
				<br />
				2. Rechtsgrundlage für die Datenverarbeitung
				<br />
				Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 S.1 lit.
				f DSGVO.
				<br />
				<br />
				3. Zweck der Datenverarbeitung
				<br />
				Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der
				Webseite an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die
				Dauer der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit
				der Webseite sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Webseite und zur
				Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu
				Marketingzwecken findet in diesem Zusammenhang nicht statt.
				<br />
				<br />
				In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1
				S.1 lit. f DSGVO.
				<br />
				<br />
				4. Dauer der Speicherung
				<br />
				Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
				erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Webseite ist dies der Fall,
				wenn die jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten in Logfiles ist dies nach
				spätestens sieben Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall
				werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden
				Clients nicht mehr möglich ist.
				<br />
				<br />
				5. Widerspruchs- und Beseitigungsmöglichkeit
				<br />
				Die Erfassung der Daten zur Bereitstellung der Webseite und die Speicherung der Daten in Logfiles ist
				für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
				Widerspruchsmöglichkeit.
				<br />
				<br />
				VI. Verwendung von Cookies
				<br />
				Beschreibung und Umfang der Datenverarbeitung
				<br />
				Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser
				bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
				Webseite auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie
				enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
				erneuten Aufrufen der Webseite ermöglicht.
				<br />
				<br />
				Wir setzen Cookies ein, um unsere Webseite nutzerfreundlicher zu gestalten. Einige Elemente unserer
				Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert
				werden kann.
				<br />
				<br />
				In den Cookies werden dabei folgende Daten gespeichert und übermittelt:
				<br />
				<br />
				Spracheinstellungen
				<br />
				Log-In-Informationen
				<br />
				2.Rechtsgrundlage für die Datenverarbeitung
				<br />
				<br />
				Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6
				Abs. 1 S.1 lit. f DSGVO.
				<br />
				<br />
				Zweck der Datenverarbeitung
				<br />
				Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Webseiten für die Nutzer zu
				vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht
				angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel
				wiedererkannt wird.
				<br />
				<br />
				Für folgende Anwendungen benötigen wir Cookies:
				<br />
				Übernahme von Spracheneinstellungen
				<br />
				Zur Verbesserung der Qualität und des Inhalts unserer Webseite.
				<br />
				In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der personenbezogenen
				Daten nach Art. 6 Abs. 1 S.1 lit. f DSGVO.
				<br />
				<br />
				Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
				<br />
				Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt.
				Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung
				der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder
				einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch
				automatisiert erfolgen. Werden Cookies für unsere Webseite deaktiviert, können möglicherweise nicht mehr
				alle Funktionen der Webseite vollumfänglich genutzt werden.
				<br />
				<br />
				VII. Eingesetzte Plugins
				<br />
				<br />
				Einsatz von Google AdWords
				<br />
				1. Umfang der Verarbeitung personenbezogener Daten
				<br />
				Wir nutzen auf unserer Webseite Google AdWords der Google Inc., 1600 Amphiteatre Parkway, Mountain View,
				CA 94043, United States. Dies ist ein Online-Werbeprogramm bei dem das Conversion-Tracking eingesetzt
				wird. Wenn Sie über eine Googleanzeige auf unserer Webseite gelangen, setzt Google AdWords ein Cookie
				auf ihren Computer. Jedem Google-AdWords-Kunden wird ein anderes Cookie zugeteilt.
				<br />
				2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
				<br />
				Die Rechtsgrundlage für die Verarbeitung ist Art.6 Abs. 1 S.1 lit. f DSGVO.
				<br />
				3. Zweck der Datenverarbeitung
				<br />
				Wir erhalten nur Kenntnis über die Gesamtanzahl der Nutzer, die auf unsere Anzeige reagiert haben. Es
				werden keine Informationen weitergegeben, mit denen wir Sie identifiziert könnten. Die Nutzung dient
				nicht der Rückverfolgung.
				<br />
				4. Dauer der Speicherung
				<br />
				Der Cookie verliert nach 30 Tagen seine Gültigkeit.
				<br />
				5. Widerspruchs- und Beseitigungsmöglichkeit
				<br />
				Sie können das Google-Conversion-Tracking unterbinden, in dem Sie das Tracking-Verfahren in Ihrem
				Browser deaktivieren.
				<br />
				<br />
				Nähere Informationen finden Sie auf https://www.google.com/intl/de/policies/privacy/ .
				<br />
				<br />
				Einsatz von Google Analytics
				<br />
				1 Umfang der Verarbeitung personenbezogener Daten
				<br />
				Wir nutzen auf unserer Webseite Google Analytics, einen Webanalysedienst der Google Inc., 1600
				Amphiteatre Parkway, Mountain View, CA 94043, United States.(„Google“). Google Analytics verwendet sog.
				„Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der
				Webseite durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser
				Webseite werden an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der
				Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google jedoch innerhalb
				von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
				Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
				Server von Google in den USA übertragen und dort gekürzt. Die IP-Anonymisierung ist auf dieser Webseite
				aktiv. Im Auftrag des Betreibers dieser Webseite wird Google diese Informationen benutzen, um Ihre
				Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um
				weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
				Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
				IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
				<br />
				<br />
				Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
				verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
				Funktionen unserer Webseite vollumfänglich werden nutzen können.
				<br />
				<br />
				2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
				<br />
				Die Rechtsgrundlage für die Verarbeitung ist Art.6 Abs. 1 S.1 lit. f DSGVO.
				<br />
				3. Zweck der Datenverarbeitung
				<br />
				Der Zweck der Verarbeitung der personenbezogenen Daten liegt in der gezielten Ansprache einer
				Zielgruppe, die bereits ein erstes Interesse durch den Seitenbesuch bekundet hat.
				<br />
				4. Dauer der Speicherung
				<br />
				Werbedaten in Serverprotokollen werden anonymisiert, indem Google nach eigenen Angaben Teile der
				IP-Adresse und Cookie-Informationen nach 9 bzw. 18 Monaten löscht.
				<br />
				5. Widerspruchs- und Beseitigungsmöglichkeit
				<br />
				Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite
				bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
				verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
				installieren: http://tools.google.com/dlpage/gaoptout?hl=de.
				<br />
				<br />
				Nähere Informationen finden Sie auf https://www.google.com/intl/de/policies/privacy/ .
				<br />
				<br />
				Einsatz von Matomo (früher Piwik)
				<br />
				1. Umfang der Verarbeitung personenbezogener Daten
				<br />
				Wir nutzen auf unserer Webseite das Open-Source-Software-Tool Matomo (ehemals PIWIK) zur Analyse des
				Surfverhaltens unserer Nutzer. Die Software setzt ein Cookie auf dem Rechner der Nutzer (zu Cookies
				siehe bereits oben). Werden Einzelseiten unserer Webseite aufgerufen, so werden folgende Daten
				gespeichert:
				<br />
				<br />
				(1) Zwei Bytes der IP-Adresse des aufrufenden Systems des Nutzers
				<br />
				(2) Die aufgerufene Webseite
				<br />
				(3) Die Webseite, von der der Nutzer auf die aufgerufene Webseite gelangt ist (Referrer)
				<br />
				(4) Die Unterseiten, die von der aufgerufenen Webseite aus aufgerufen werden
				<br />
				(5) Die Verweildauer auf der Webseite
				<br />
				(6) Die Häufigkeit des Aufrufs der Webseite
				<br />
				Die Software läuft dabei ausschließlich auf den Servern unserer Webseite. Eine Speicherung der
				personenbezogenen Daten der Nutzer findet nur dort statt. Eine Weitergabe der Daten an Dritte erfolgt
				nicht.
				<br />
				<br />
				Ohne Einwilligung des Nutzers sollte „Automatically Anonymize Visitor IPs“ aktiviert sein:
				<br />
				Die Software ist so eingestellt, dass die IP-Adressen nicht vollständig gespeichert werden, sondern 2
				Bytes der IP-Adresse maskiert werden (Bsp.: 192.168.xxx.xxx). Auf diese Weise ist eine Zuordnung der
				gekürzten IP-Adresse zum aufrufenden Rechner nicht mehr möglich.
				<br />
				<br />
				2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
				<br />
				Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.1 lit. f
				DSGVO.
				<br />
				3. Zweck der Datenverarbeitung
				<br />
				Die Verarbeitung der personenbezogenen Daten der Nutzer ermöglicht uns eine Analyse des Surfverhaltens
				unserer Nutzer. Wir sind in durch die Auswertung der gewonnen Daten in der Lage, Informationen über die
				Nutzung der einzelnen Komponenten unserer Webseite zusammenzustellen. Dies hilft uns dabei unsere
				Webseite und deren Nutzerfreundlichkeit stetig zu verbessern. In diesen Zwecken liegt auch unser
				berechtigtes Interesse in der Verarbeitung der Daten nach Art. 6 Abs. 1 lit. f DSGVO. Durch die
				Anonymisierung der IP-Adresse wird dem Interesse der Nutzer an deren Schutz personenbezogener Daten
				hinreichend Rechnung getragen.
				<br />
				4. Dauer der Speicherung
				<br />
				Die Daten werden gelöscht, sobald sie für unsere Aufzeichnungszwecke nicht mehr benötigt werden. In
				unserem Fall ist dies nach 10 Jahren der Fall. 5. Widerspruchs- und Beseitigungsmöglichkeit
				<br />
				Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt.
				Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung
				der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder
				einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch
				automatisiert erfolgen. Werden Cookies für unsere Webseite deaktiviert, können möglicherweise nicht mehr
				alle Funktionen der Webseite vollumfänglich genutzt werden. Falls Opt-Out-Option angeboten wird:
				<br />
				Wir bieten unseren Nutzern auf unserer Webseite die Möglichkeit eines Opt-Out aus dem Analyseverfahren.
				Hierzu müssen Sie dem entsprechenden Link folgen. Auf diese Weise wird ein weiterer Cookie auf ihrem
				System gesetzt, der unserem System signalisiert die Daten des Nutzers nicht zu speichern. Löscht der
				Nutzer den entsprechenden Cookie zwischenzeitlich vom eigenen System, so muss er den Opt-Out-Cookie
				erneut setzten. Nähere Informationen zu den Privatsphäreeinstellungen der Matomo Software finden Sie
				unter folgendem Link: https://matomo.org/docs/privacy/
			</p>
		</div>
	</>
);

export default DataProtection;
